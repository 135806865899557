import React from "react";
import PropTypes from "prop-types";

import { Box, TextV2 } from "shipnow-mercurio";

import { ReactComponent as Illustration } from "../../../assets/images/mercurio_error_505.svg";

const ErrorContent = (props) => {
  const { error, errorStack, message, title } = props;

  return (
    <Box className="has-text-centered" margin="y-50">
      <Illustration style={{ maxWidth: "320px", width: "70%" }} />

      <TextV2 align="center" margin="t-24" size="title">
        {title}
      </TextV2>

      <Box maxWidth="350px" style={{ margin: "auto" }}>
        {message && (
          <TextV2 align="center" margin="t-16">
            {message}
          </TextV2>
        )}

        {error && (
          <TextV2 align="center" color="blackDisabled" margin="t-16">
            {error.message}
          </TextV2>
        )}
      </Box>

      {errorStack && (
        <details style={{ whiteSpace: "pre-wrap", marginTop: "16px" }}>
          {errorStack}
        </details>
      )}
    </Box>
  );
};

ErrorContent.propTypes = {
  error: PropTypes.object,
  errorStack: PropTypes.object,
  message: PropTypes.string,
  title: PropTypes.string,
};

ErrorContent.defaultProps = {
  error: null,
  errorStack: null,
  message: null,
  title: " Ups! Algo salió mal...",
};

export default ErrorContent;
