import { computed, action, observable } from "mobx";

import { getNotDeliveredReason } from "../utils/visitFailedWordings";

import { Model } from "../lib";

import { ESTRADA, ESTUDIONUBE, TOM } from "../assets/constants/warehouses";

import { sortByDate } from "../utils/dates";
import moment from "moment";

export default class Tracker extends Model {
  urlRoot = "/tracking";

  @observable currentOrder;

  @action
  afterSetData() {
    if (!this.currentOrder && this.order) {
      this.currentOrder = this._store.appStore.orders.getFromData(this.order);
    }
  }
  @computed
  get filteredEvents() {
    const filterCondition =
      this.orderIsExchange && this.orderIsPAP && !this.orderIsOcaPAP;

    const e = this.events
      ? this.events.filter((e) => !filterCondition || e.status !== "delivered")
      : [];

    return e;
  }

  @computed
  get cookedEvents() {
    let allEvents = [];

    if (this.events) {
      allEvents = this.filteredEvents
        .map((e) => ({
          title: this.getEventTitle(e.status),
          description: this.getEventMessage(e),
          time: e.changed_at,
          type: "event",
          color: this.getEventColors(e.status),
        }))
        .reverse();
    }

    if (this.messages) {
      this.messages.forEach((e) => {
        allEvents.push({
          type: "email",
          channel: e.channel,
          time: e.sent_at || e.delivered_at,
          status: e.status,
          subject: e.subject,
          description: e.body,
          read_at: e.read_at,
        });
      });
    }

    allEvents.sort((a, b) => sortByDate(a.time, b.time));

    return allEvents;
  }

  getDescription(status) {
    const carrier = this.order.shipping_option.carrier_code;
    const getNewExchangeWording = () => {
      if (this.orderIsPAP) {
        if (carrier === "shipnow") {
          return "Ya agendamos una visita.";
        } else if (this.orderIsOcaPAP) {
          return "OCA retirará tu devolución por tu domicilio.";
        } else {
          return "El correo ya agendó una visita. ";
        }
      } else if (this.orderIsSAP) {
        return `Llévala a la sucursal de ${this.order.shipping_service.carrier.name} que te indicamos por email. `;
      } else if (
        this.order.shipping_option.service_code === "exchange_warehouse"
      ) {
        if (
          this.order.ship_from_warehouse_id === TOM ||
          this.order.ship_from_warehouse_id === ESTUDIONUBE
        ) {
          return "Llévala al Tortugas Open Mall para que podamos entregársela al vendedor. ";
        } else if (this.order.ship_from_warehouse_id === ESTRADA) {
          return "Llévala al depósito Estrada para que podamos entregársela al vendedor.";
        }
      }
    };

    const getVisitScheduledExchange = () => {
      const carrier = this.order.shipping_option.carrier_code;
      if (this.orderIsPAP) {
        return carrier === "shipnow"
          ? "Ya agendamos una visita."
          : "El correo ya agendó una visita.";
      }
    };

    const getDispatchedExchange = () => {
      if (this.orderIsPAP) {
        if (this.orderIsOcaPAP) {
          return "OCA la llevará a nuestros depósitos.";
        }
        if (this.isShipFull) {
          return "A continuación, la llevaremos a los depósitos de shipnow.";
        } else {
          return "Ahora, la procesaremos para devolverla al vendedor.";
        }
      } else if (this.orderIsSAP) {
        return `A continuación, ${this.order.shipping_service.carrier.name} la llevará a nuestros depósitos.`;
      } else {
        if (this.isShipFull) {
          return "El vendedor la retirará en nuestro depósito.";
        } else {
          return "A continuación, la procesaremos para llevarla al depósito del vendedor.";
        }
      }
    };

    const getDeliveredExchange = () => {
      if (this.orderIsToWs || this.orderIsSAP) {
        if (this.isShipFull) {
          return "El vendedor la retirará en nuestro depósito.";
        } else {
          return "A continuación, la procesaremos para llevarla al depósito del vendedor.";
        }
      } else {
        if (this.orderIsOcaPAP) {
          return "A continuación, la procesaremos para llevarla al depósito del vendedor.";
        }
        if (this.isShipFull) {
          return "El vendedor la retirará en nuestro depósito.";
        } else {
          return "Ahora, la procesaremos para devolverla al vendedor.";
        }
      }
    };

    const getNotDeliveredOrCancelledExchange = () => {
      if (this.orderIsSAP) {
        return `Superaste el plazo de 30 días para entregar tu devolución a ${this.order.shipping_service.carrier.name}.`;
      } else if (this.orderIsPAP) {
        if (this.orderIsOcaPAP) {
          return "Tu devolución no llegó al depósito de shipnow.";
        }
        return "No pudimos retirar tu devolución.";
      } else if (
        this.order.shipping_option.service_code === "exchange_warehouse"
      ) {
        if (
          this.order.ship_from_warehouse_id === TOM ||
          this.order.ship_from_warehouse_id === ESTUDIONUBE
        ) {
          return "Superaste el plazo de 30 días para entregar tu devolución en el TOM. ";
        } else if (this.order.ship_from_warehouse_id === ESTRADA) {
          return "Superaste el plazo de 30 días para entregar tu devolución en depósito Estrada.";
        }
      }
    };
    const description = {
      cancelled: "La marca la canceló.",
      not_delivered: this.orderIsExchange
        ? getNotDeliveredOrCancelledExchange()
        : "El correo no pudo entregar tu pedido.",
      delivered: this.orderIsExchange
        ? getDeliveredExchange()
        : "¡Buenísimo! Asegurate de haber recibido todo bien y por cualquier problema, no dudes en contactarnos.",
      visit_dispatched: "En el transcurso del día, retiraremos tu devolución.",
      visit_shipping: this.orderIsExchange
        ? "En el transcurso del día, retiraremos tu devolución."
        : "En el transcurso del día el correo va a estar visitando tu domicilio para entregarte el pedido. ¡Asegurate que haya alguien mayor de 18 años para recibirlo!",
      visit_scheduled: this.orderIsExchange
        ? getVisitScheduledExchange()
        : "El correo ya agendó una visita para los próximos días. Deberían visitarte pronto.",
      check_in_visit_scheduled: `Agendamos una visita para devolverla a ${this.order.brand_name}.`,
      visit_without_news:
        "Estamos comunicándonos nosotros con el correo para saber qué pudo haber pasado y mantenerte al tanto.",
      awaiting_pickup:
        "Ya podés ir a buscar tu pedido. Recordá que tenés 5 días para ir a buscarlo.",
      in_post_office:
        "Tu pedido está cada vez más cerca. Ya llegó al centro de distribución del correo.",
      shipped: this.orderIsExchange
        ? `A continuación, ${this.order.shipping_service.carrier.name} la llevará a nuestros depósitos.`
        : "¡El correo ya tiene tu pedido!",
      check_in_processed: `Preparamos tu devolución para el retorno a ${this.order.brand_name}.`,
      check_in_completed: "Entregamos tu devolución al vendedor.",
      check_in_shipped: "En el trancurso del día, entregaremos tu devolución.",

      dispatched: this.orderIsExchange
        ? getDispatchedExchange()
        : "Tu pedido ya está listo para viajar a su destino. Ahora sólo tiene que retirarlo el correo por nuestro depósito.",
      ready_to_ship:
        "Tu pedido ya está preparado. Pronto estaremos haciendo controles de calidad para garantizar su embalaje.",
      packing_slip:
        "Estamos preparando tu pedido con mucho cuidado para que lo recibas tal cual vos lo pediste.",
      ready_to_pick:
        "Ya tenemos todo lo necesario para empezar a preparar tu pedido.",
      recovery_awaiting_pickup:
        "Cómo no se pudo entregar en tu domicilio, el pedido está esperando en una sucursal del correo.",
      rescheduling_visit:
        "El correo ya planificó una nueva visita a tu domicilio. Recordá que tenés que estar presente vos o cualquier persona mayor de 18 años para que te podamos entregar el pedido.",
      waiting_agreement:
        "El correo no te pudo encontrar así que van a estar comunicándose con vos pronto para coordinar personalmente la entrega.",
      new: this.orderIsExchange
        ? getNewExchangeWording()
        : "¡Pedido recién creado!",
    };
    return description[status];
  }

  getEventColors(status) {
    const statusColors = {
      delivered: "primary",
      not_delivered: "warning",
      cancelled: "error",
      ready_to_pick: "primary",
      ready_to_ship: "primary",
      packing_slip: "primary",
      shipped: "primary",
      awaiting_pickup: "primary",
      recovery_awaiting_pickup: "primary",
    };

    return statusColors[status];
  }
  getPickupDays(service_code) {
    if (service_code === "pickup_warehouse") {
      return "30";
    }
    if (service_code === "garbarino_pickup") {
      return "10";
    }
    return "5";
  }

  getEventTitle(status) {
    const getDispatchedExchange = () => {
      if (this.orderIsToWs) {
        if (this.isShipFull) {
          return "¡Tu devolución llegó a nuestros depósitos!";
        } else {
          return "¡Recibimos tu devolución!";
        }
      } else if (this.orderIsSAP) {
        return "Dejaste tu devolución en el correo";
      } else {
        if (this.orderIsOcaPAP) {
          return "Devolución retirada";
        }

        return "Retiramos tu devolución";
      }
    };
    const getDeliveredExchange = () => {
      if (this.orderIsToWs || this.orderIsSAP) {
        if (this.isShipFull) {
          return "¡Tu devolución llegó a nuestros depósitos!";
        } else {
          return "¡Recibimos tu devolución!";
        }
      } else {
        if (this.orderIsOcaPAP) {
          return "Recibimos tu devolución";
        }
      }
    };
    const getNotDeliveredOrCancelledExchange = () => {
      if (this.orderIsPAP) {
        if (this.orderIsOcaPAP) {
          return "Devolución no recibida";
        }
        return "Devolución no retirada";
      } else {
        return "Devolución no recibida";
      }
    };
    const getNewStatusTitle = () => {
      if (this.orderIsExchange) {
        if (this.orderIsPAP) {
          if (this.orderIsOcaPAP) {
            return "Devolución solicitada";
          }
          return "Visita al domicilio programada";
        } else if (
          this.orderIsSAP ||
          this.order.shipping_service.code === "exchange_warehouse"
        ) {
          return "Devolución solicitada";
        } else {
          return "Devolución programada";
        }
      } else {
        return "Creado";
      }
    };

    const titles = {
      recovery_awaiting_pickup: "Tu pedido está esperando en el correo",
      rescheduling_visit: "Una nueva visita fue coordinada",
      waiting_agreement: "El correo se pondrá en contacto con vos",
      not_delivered: this.orderIsExchange
        ? getNotDeliveredOrCancelledExchange()
        : "No se pudo entregar tu pedido",
      delivered: this.orderIsExchange ? getDeliveredExchange() : "¡Entregado!",
      visit_without_news: "No tuvimos noticias del correo",
      visit_failed: this.orderIsExchange
        ? "Devolución fallida"
        : "Visita fallida",

      visit_shipping: "¡Visita en camino!",
      visit_dispatched: "¡Visita en camino!",
      visit_scheduled: "Visita al domicilio programada",
      check_in_visit_scheduled: "Visita programada",
      awaiting_pickup: "Ya puede ser retirado",
      in_post_office: "Llegó a la oficina del correo",
      shipped: this.orderIsExchange
        ? "Dejaste tu devolución en el correo"
        : "En manos del correo",
      dispatched: this.orderIsExchange ? getDispatchedExchange() : "Despachado",
      check_in_processed: "Devolución procesada",
      check_in_shipped: "En camino al vendedor",

      check_in_completed: "¡Devolvimos tu devolución!",

      ready_to_ship: "Preparado",
      packing_slip: "En preparación",
      ready_to_pick: "Listo para preparar",
      new: getNewStatusTitle(),

      cancelled: this.orderIsExchange ? "Devolución cancelada" : "Cancelado",
    };

    return titles[status];
  }

  getEventMessage(event) {
    let msg;
    const order = this.order;

    let carrier = order.shipping_option.carrier_code;
    if (carrier === "shipnow") {
      switch (event["status"]) {
        case "awaiting_pickup":
          msg =
            "¡Sí! Ya podés ir a buscar tu pedido. Acá te dejamos toda la información necesaria, y recordá que tenés " +
            this.getPickupDays(order["shipping_option"]["service_code"]) +
            " días para ir a buscarlo. ¡Apurate!";
          break;
        case "visit_failed":
          if (event["visit_id"]) {
            let visit = order["shipment"]["visits"].find(
              (v) => v["id"] === event["visit_id"]
            );
            const notDeliveredReason = visit["not_delivered_reason"];
            const orderIsExchange = this.orderIsExchange;
            const errorMessage = getNotDeliveredReason(
              notDeliveredReason,
              orderIsExchange
            );
            msg = errorMessage;
          } else {
            msg =
              "Puede que nadie contestara, que no estuvieses, o que el correo no haya podido dar con tu domicilio. El hecho es que correo se acercó a tu domicilio y no te pudieron encontrar. ";
          }
          break;
        default:
          msg = this.getDescription(event["status"]);
      }
    } else {
      switch (event["status"]) {
        case "awaiting_pickup":
          msg =
            "¡Sí! Ya podés ir a buscar tu pedido. Acá te dejamos toda la información necesaria, y recordá que tenés " +
            this.getPickupDays(order["shipping_option"]["service_code"]) +
            " días para ir a buscarlo. ¡Apurate!";
          break;
        case "visit_failed":
          if (event["visit_id"]) {
            let visit = order["shipment"]["visits"].find(
              (v) => v["id"] === event["visit_id"]
            );
            const notDeliveredReason = visit["not_delivered_reason"];
            const orderIsExchange = this.orderIsExchange;
            const errorMessage = getNotDeliveredReason(
              notDeliveredReason,
              orderIsExchange
            );
            msg = errorMessage;
          } else {
            msg =
              "Puede que nadie contestara, que no estuvieses, o que el correo no haya podido dar con tu domicilio. El hecho es que correo se acercó a tu domicilio y no te pudieron encontrar. ";
          }
          break;
        default:
          msg = this.getDescription(event["status"]);
      }
    }
    return msg;
  }

  saveFeedback(params) {
    let url = `/shipments/${this.order.shipment.id}/feedback`;

    return this._store.appStore.coreAPIClient.post(url, params, false);
  }
  @computed
  get isShipFull() {
    return (
      this.currentOrder.currentAccount.picking_preference_own === "warehouse"
    );
  }
  @computed
  get orderIsPAP() {
    return this.order.shipping_option.service_type === "ship_pap";
  }
  @computed
  get orderIsOcaPAP() {
    return this.order.shipping_option.service_code === "oca_pap_exchange";
  }
  @computed
  get orderIsSAP() {
    return this.order.shipping_option.service_type === "ship_sap";
  }
  @computed
  get orderIsToWs() {
    return this.order.shipping_service.code === "exchange_warehouse";
  }

  @computed
  get orderIsExchange() {
    return this.order.type === "exchange";
  }
  @computed
  get isShipTohub() {
    const order = this.order;
    const shipType = order.shipping_option.service_type;

    if (order.shipping_option.carrier_code === "pickup") return true;

    return ["ship_pas", "ship_sas", "exchange"].includes(shipType);
  }

  @computed
  get isDelayed() {
    return moment().isAfter(moment(this.currentOrder.maximum_delivery));
  }

  hasAFailedVisit() {
    return !!this.lastFailedVisit();
  }

  feedbackVisitFailedYesQuestions() {
    return [
      {
        q: "¿Encontraste una hoja de visita en tu puerta?",
        field: "not_received_note",
        options: {
          Si: "true",
          No: "false",
        },
      },
      {
        q: "¿Se comunicó el correo con vos por teléfono?",
        field: "not_received_phone",
        options: {
          Si: "true",
          No: "false",
        },
      },
      {
        q: "¿Tiene un timbre o llamador funcionando el domicilio?",
        field: "not_received_bell",
        options: {
          Si: "true",
          No: "false",
        },
      },
    ];
  }

  feedbackVisitFailedNoQuestions() {
    return [
      {
        q: "¿Encontraste una hoja de visita en tu puerta?",
        field: "not_received_note",
        options: {
          Si: "true",
          No: "false",
        },
      },
      {
        q: "¿Se comunicó el correo con vos por teléfono?",
        field: "not_received_phone",
        options: {
          Si: "true",
          No: "false",
        },
      },
      {
        q:
          "¿Sabías que debía haber alguien en determinado horario para recibir el pedido?",
        field: "not_received_aware",
        options: {
          Si: "true",
          No: "false",
        },
      },
    ];
  }

  feedbackDeliveredYesButQuestions() {
    return [
      {
        q: "¿Qué problemas tuviste con tu pedido?",
        field: "bad_visit_feedback_reason",
        options: {
          "El contenido está roto o maltratado": "broken",
          "Faltan artículos de mi pedido": "incomplete",
          "No es lo que yo pedí": "mixed",
          "Tengo otro problema": "other",
        },
      },
    ];
  }

  feedbackNotDeliveredYesQuestions() {
    return [
      {
        q: "¿Qué problemas tuviste con tu pedido?",
        field: "not_delivered_problem",
        options: {
          "Nunca tuve noticias del correo": "courier",
          "El correo nunca encontró a nadie en el domicilio": "not_present",
          "No pude coordinar con el correo la entrega": "courier_comunication",
          "El correo devolvió mi pedido": "devolution",
          "Tuve otro problema": "other",
        },
      },
    ];
  }

  feedbackDeliveredNoQuestions() {
    return [
      {
        q:
          "¿Puede haber recibido el pedido otra persona en tu nombre (un familiar o un encargado)?",
        field: "not_received_another",
        options: {
          Si: "true",
          No: "false",
        },
      },
      {
        q: "¿Estuviste vos u otra persona en el domicilio todo el tiempo?",
        field: "not_received_presence",
        options: {
          Si: "true",
          No: "false",
        },
      },
      {
        q: "¿Encontraste una hoja de visita en tu puerta?",
        field: "not_received_note",
        options: {
          Si: "true",
          No: "false",
        },
      },
    ];
  }

  feedbackDeliveredYesQuestions() {
    return [
      {
        q: "Calificá tu experiencia",
        field: "delivered_yes_questions",
        options: {
          Excelente: "excelent",
          "Muy Buena": "very_good",
          Buena: "good",
          Regular: "regular",
          Mala: "bad",
        },
      },
    ];
  }

  lastVisit() {
    if (!this.order.shipment || !this.order.shipment.visits.length) return;

    return this.order.shipment.visits[this.order.shipment.visits.length - 1];
  }

  lastEvent() {
    if (!this.events || !this.events.length) return;

    return this.filteredEvents[this.filteredEvents.length - 1];
  }

  lastEventStatus() {
    return this.lastEvent()["status"];
  }

  isLastVisitNotDelivered() {
    const visit = this.lastVisit();

    if (!visit) return false;

    return visit.status === "not_delivered";
  }

  lastFailedVisit() {
    if (!this.order.shipment) return;

    return this.order.shipment.visits
      .reverse()
      .find((v) => v.status === "not_delivered");
  }

  hasFeedback() {
    if (!this.valid_token) return false;
    if (!this.order.shipment) return false;

    return (
      ["delivered", "not_delivered"].includes(this.order.status) &&
      !!this.feedbacks.toJS().lenght
    );
  }

  hasCsatRate() {
    const result = this.feedbacks.some(
      (f) => f.feedback_question.code === "shipping_csat"
    );
    return result;
  }

  getStatusQuestions() {
    const order = this.currentOrder;
    const statusQuetions = [];

    statusQuetions.push({
      text: order.isPAP
        ? "¿Cuándo llega mi pedido?"
        : "¿Cuándo llega mi pedido a la sucursal?",
      route: "/status/arrival",
    });

    statusQuetions.push({
      text: "¿Con qué correo se envía?",
      route: "/status/carrier",
    });

    if (order.isPAS) {
      statusQuetions.push({
        text: "¿A qué sucursal llega?",
        route: "/status/postoffice",
      });
    }

    if (order.isPAS) {
      statusQuetions.push({
        text: "¿Cuantos días tengo para retirar el pedido? ",
        route: "/status/postoffice_timeframe",
      });
    }

    statusQuetions.push({
      text: order.isPAP
        ? "¿Puede recibirlo otra persona?"
        : "¿Puede retirarlo otra persona?",
      route: "/status/receiver",
    });

    return statusQuetions;
  }

  getIssuesQuestions() {
    const issuesQuestions = [];

    if (
      !["delivered", "not_delivered", "cancelled"].includes(
        this.currentOrder.status
      )
    ) {
      issuesQuestions.push({
        text: "Mi pedido está demorado",
        route: "/issues/delayed",
      });
    }

    if (
      !["delivered", "not_delivered"].includes(this.currentOrder.status) &&
      this.currentOrder.isPAP
    ) {
      issuesQuestions.push({
        text: "Modificar datos de entrega",
        route: "/issues/data",
      });
    }

    if (this.currentOrder.isPAP && this.currentOrder.status === "shipped") {
      issuesQuestions.push({
        text: "El correo no pudo completar la visita",
        route: "/issues/fail_visit",
      });
    }

    if (["delivered", "shipped"].includes(this.currentOrder.status)) {
      issuesQuestions.push({
        text: "Tengo problemas con el pedido recibido",
        route: "/issues/broke",
      });
    }

    return issuesQuestions;
  }

  getHelpBaseQuestions() {
    const sections = [];
    const statusQuestions = this.getStatusQuestions();
    const issuesQuestions = this.getIssuesQuestions();

    if (statusQuestions.length > 0) {
      sections.push({
        title: "Estado de mi pedido",
        questions: statusQuestions,
      });
    }

    if (issuesQuestions.length > 0) {
      sections.push({
        title: "Tengo problemas con mi pedido",
        questions: issuesQuestions,
      });
    }

    return {
      title: "¿Hola en qué te podemos ayudar?",
      sections,
    };
  }

  getHelpIssuesDataQuestions() {
    return {
      title: "Modificar datos de entrega",
      sections: [
        {
          title: "Estado de mi pedido",
          questions: [
            {
              text: "Cambio de domicilio de envio",
              route: "/address",
            },
            /*             {
              text: "Aclaracion sobre domicilio de envio",
              route: "/comment",
            },
 */
            {
              text: "Aclaracion sobre fecha y hora de entrega",
              route: "/schedule",
            },
          ],
        },
      ],
    };
  }

  getHelpIssuesBrokeQuestions() {
    return {
      title: "Tengo problemas con el pedido recibido",
      sections: [
        {
          title: "Contanos un poco más",
          questions: [
            {
              text: "El pedido llegó dañado",
              route: "/broken",
            },
            {
              text: "El pedido llegó incompleto",
              route: "/incomplete",
            },
            {
              text: "Es un producto diferente a lo acordado",
              route: "/wrong_product",
            },
            {
              text: "Quiero realizar un cambio (talla, color, modelo, etc)",
              route: "/exchange",
            },
            {
              text: "Aparece como entregado y no lo recibí",
              route: "/not_delivered",
            },
          ],
        },
      ],
    };
  }

  isAfterMaximumDelivery() {
    return moment(this.currentOrder.maximum_delivery).isBefore(moment());
  }

  getHelpTreePrevLocation(match, location) {
    const id = match.params.id;
    const deepestLeaves = [
      "broken",
      "incomplete",
      "wrong_product",
      "exchange",
      "not_delivered",
      "address",
      "comment",
      "schedule",
    ];

    var parts = location.pathname.split("/");
    var lastPart = parts[parts.length - 1]; // Or parts.pop();

    const url =
      lastPart === "support"
        ? `/track/${id}`
        : deepestLeaves.includes(lastPart)
        ? "/" + parts.slice(1, parts.length - 1).join("/")
        : match.url;

    return url + location.search;
  }

  getSaleforceReason(match) {
    const {
      section_issues,
      section_status,
      data_issue,
      broke_issue,
    } = match.params;

    if (broke_issue) {
      switch (broke_issue) {
        case "broken":
          return "Producto Dañado";
        case "incomplete":
          return "Producto Incompleto";
        case "wrong_product":
          return "Producto Diferente";
        case "not_delivered":
          return "Entregado y no lo recibio";
        default:
          return "";
      }
    }

    if (data_issue) {
      switch (data_issue) {
        case "address":
          return "Modificacion de datos de envio";
        default:
          return "";
      }
    }

    if (section_status) {
      switch (section_status) {
        case "arrival":
          return "Estado de mi pedido";
        default:
          return "";
      }
    }

    if (section_issues) {
      switch (section_issues) {
        case "data":
          return "Modificacion de datos de envio";
        case "delayed":
          return "Mi pedido esta demorado";
        case "fail_visit":
          return "Mi pedido tuvo una visita fallida";
        default:
          return "";
      }
    }

    return "";
  }

  getSaleforceSubject() {
    return `Comprador - ${this.currentOrder.seller_name} - ${this.currentOrder.buyerInfo.name} ${this.currentOrder.buyerInfo.last_name} - Orden: ${this.currentOrder.id}`;
  }

  pickupDays() {
    return this._store.appStore.ui.workingDaysAhead(
      this.currentOrder.shipment.in_post_office_at,
      5
    );
  }

  inFinalStatus() {
    let validOrderStatuses = ["cancelled", "not_delivered", "delivered"];
    let validCheckInStatuses = [];

    if (this.currentOrder.isReturn()) {
      if (this.isShipFull) {
        validOrderStatuses.push("shipped");
      } else {
        validCheckInStatuses.push("completed");
      }
    }

    const checkIn = this.currentOrder.check_in;

    return (
      validOrderStatuses.includes(this.currentOrder.status) ||
      (checkIn && validCheckInStatuses.includes(checkIn.status))
    );
  }
  showTrackingFeedback() {
    return this.valid_token && this.inFinalStatus();
  }
}
