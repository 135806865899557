import { Store } from "../lib";
import { GoodsInItem } from "../models";

export default class GoodsInItemsStore extends Store {
  model = GoodsInItem;
  getGoodsInItemUnits(id) {
    return this.appStore.coreAPIClient.sendRequest(
      `/goods_in_items/${id}/goods_in_units`,
      "GET"
    );
  }
}
