import { Model } from "../lib";
import { computed, action, observable, toJS } from "mobx";

export default class Variant extends Model {
  urlRoot = "/variants";

  @observable stocks = null;
  @observable publications = null;

  requestErrors = {
    "must be a valid account": "La cuenta es inexistente o incorrecta",
    "Title can't be blank": "El título no puede estar vacío",
    "External reference has already been taken":
      "El codigo de barras ya esta en uso",
  };

  constructor(attributes, store) {
    let defaultAttributes = {
      title: null,
      product_id: null,
      product: null,
      external_reference: null,
      external_reference_user: null,
      seller_id: null,
      images: null,
      image_url: null,
      weight: null,
      height: null,
      length: null,
      width: null,
      supplier: null,
      price: {
        buy: null,
        retail: null,
        wholesale: null,
      },
      stock: {
        allocated: 0,
        available: 0,
        committed: 0,
        on_hand: 0,
      },
      properties: {},
      declared_dimensions: {},
      dimensions: {
        weight: null,
        height: null,
        length: null,
        width: null,
      },
    };

    super(attributes, store, defaultAttributes);
  }
  @action
  afterSetData() {
    if (this.product) {
      this.product_id = this.product.id;
    }
  }

  @computed
  get isFullfilment() {
    return this.picking_preference === "warehouse";
  }
  @computed
  get webURI() {
    return `${this._store.baseWebURI}/${this.id}`;
  }

  abilities = {
    archived: (loggedInUser) => !this.archived,
    kind_toogled: (loggedInUser) => true,
  };

  @computed
  get companyName() {
    return this.seller_name;
  }

  @computed
  get externalReference() {
    return this.external_reference_user || this.external_reference || this.id;
  }

  @computed
  get computedPrice() {
    if (!this.price) return 0;

    return this.price.retail || this.price.wholesale || this.price.buy || 0;
  }

  @computed
  get cookedDimentions() {
    const noDimension = 0;
    const height = this.declared_dimensions.height || noDimension;
    const width = this.declared_dimensions.width || noDimension;
    const length = this.declared_dimensions.length || noDimension;

    return ` ${length} x ${width}x ${height} cm`;
  }

  @computed
  get volume() {
    const height = this.dimensions.height || this.declared_dimensions.height;
    const width = this.dimensions.width || this.declared_dimensions.width;
    const length = this.dimensions.length || this.declared_dimensions.length;

    return length * width * height * 0.000001; //in m3
  }

  @computed
  get cookedMinStock() {
    if (this.min_stock_own === 0) {
      return "Sin control de stock";
    }

    if (!this.min_stock_own) {
      return "10 unidades";
    }

    return `${this.min_stock_own} unidades`;
  }

  @computed
  get pickingPreference() {
    return (
      this.picking_preference_own ||
      this.picking_preference ||
      this.account.picking_preference_own ||
      this.account.picking_preference
    );
  }

  @computed
  get cookedPickingPreference() {
    if (this.pickingPreference === "cross_docking") {
      return "El producto se despacha a demanda";
    }

    return "El producto despacha desde shipnow HQ.";
  }

  @computed
  get cookedWeight() {
    return `${this.declared_dimensions.weight || 0} gramos`;
  }

  @computed
  get minStock() {
    return this.min_stock_own || this.min_stock;
  }

  @computed
  get stockStatus() {
    if (!this.stock || this.stock.available === 0) return "Sin stock";

    if (this.minStock && this.stock.available < this.minStock)
      return "Bajo stock";

    return "Con stock";
  }

  @computed
  get hasStock() {
    return this.stockStatus !== "Sin stock";
  }

  @computed
  get withoutStock() {
    return this.stockStatus === "Sin stock";
  }

  @computed
  get stockAvalibleResume() {
    if (!this.stock) return "Sin stock";

    return this.withoutStock
      ? "Sin stock"
      : `${this.stock.available} disponible`;
  }

  @computed
  get stockCommittedResume() {
    if (!this.stock) return null;

    const committedStock = this.stock.committed;

    return committedStock === 0 ? null : `${committedStock} comprometidas`;
  }

  @computed
  get stockStatusColor() {
    const color = {
      "Con stock": "success",
      "Sin stock": "error",
      "Bajo stock": "warning",
    };

    return color[this.stockStatus];
  }

  @computed
  get stockStatusIcon() {
    const icon = {
      "Bajo stock": "caution",
      "Con stock": "success",
      "Sin stock": "error",
    };

    return icon[this.stockStatus];
  }

  @computed
  get stockStatusLevel() {
    const level = {
      "Con Stock": 4,
      "Bajo Stock": 2,
      "Sin Stock": 0,
    };

    return level[this.stockStatus];
  }

  @computed
  get isSelected() {
    return this._store.appStore.ui.lists.selectedVariants.some(
      (item) => item.id === this.id
    );
  }

  @computed
  get cookedName() {
    if (!this.title) return "";
    return (
      this.title.toLowerCase().charAt(0).toUpperCase() +
      this.title.toLowerCase().slice(1)
    );
  }

  @action
  archive() {
    this.archived = true;
    this.save();
  }

  @action
  unArchive() {
    this.archived = false;
    this.save();
  }

  @action
  changeAttribute(attrName, value) {
    let attributes = {};
    if (value) {
      attributes = toJS(this.properties);
      attributes[attrName] = value;

      this.properties = observable(attributes);
    } else {
      // this is because core twrow a error when you save some like  color = null
      attributes = toJS(this.properties);
      let newAttributes = {};

      for (const key in attributes) {
        if (key !== attrName) {
          newAttributes[key] = attributes[key];
        }
      }

      this.properties = observable(newAttributes);
    }

    return this;
  }

  @action
  changeParent(parentProd) {
    this.product_id = parentProd.id;
    return this;
  }

  updateValues(values) {
    for (const key in values) {
      if (values.hasOwnProperty(key)) {
        this[key] = values[key];
      }
    }
  }

  @action
  fetchImages(forceFetch = false) {
    if (this.images && !forceFetch) {
      return this;
    } else {
      this.beginUpdate();
      this._store.appStore.variants.searchImages(this.id, {}).andThen((res) => {
        this.images = res.toJS();

        this.endUpdate();
      });
    }
  }

  @action
  addImage(image) {
    this.images.unshift(image);

    this.save();

    return this;
  }

  @action
  deleteImage(image) {
    const imgs = this.images.filter(
      (i) =>
        (image.id && i.id !== image.id) || i.original_url !== image.original_url
    );

    this.images = imgs;

    this.save();

    return this;
  }

  @action
  fetchStock(extraParams = null) {
    if (this.stocks) {
      return this;
    } else {
      this.beginUpdate();
      let params = { per_page: 200 };
      if (extraParams) {
        params = { ...params, ...extraParams };
      }
      this._store.appStore.variants
        .searchStock(this.id, params)
        .andThen((res) => {
          this.stocks = res.toJS();
          this.endUpdate();
        });
    }
    return this;
  }

  @action
  fetchPublications() {
    if (this.publications) {
      return this;
    } else {
      this.beginUpdate();
      this._store.appStore.publications
        .search({ variant_id: this.id })
        .andThen((res) => {
          this.publications = res.toArray();
          this.endUpdate();
        });
    }
    return this;
  }

  get isProduct() {
    return false;
  }

  get isKit() {
    return false;
  }

  get isVariant() {
    return true;
  }

  isGoodsInItem() {
    return false;
  }

  validReference() {
    return this.external_reference && this.external_reference !== "";
  }

  setData(data) {
    this.beginUpdate();
    this.external_reference = data.external_reference;
    this.external_reference_user = data.external_reference_user;
    this.title = data.title;
    this.image_url = data.image_url;
    this.price.retail = data.retail_price;
    this.price.buy = data.buyer_price;
    this.declared_dimensions.weight = data.weight;
    this.declared_dimensions.height = data.height;
    this.declared_dimensions.length = data.length;
    this.declared_dimensions.width = data.width;
    this.supplier = data.supplier;
    this.quantity = data.quantity;

    this.seller_id = parseInt(data.account_id || data.seller_id);

    this.endUpdate();
  }
}
