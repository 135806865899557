import React from "react";
import PropTypes from "prop-types";

import { Flex } from "shipnow-mercurio";

export const PanelTitleLayout = (props) => {
  const { children, isMobile } = props;

  return (
    <Flex
      style={{ height: isMobile ? "auto" : "38px" }}
      margin={isMobile ? "b-16" : "b-20"}
      justifyContent="space-between"
    >
      {children}
    </Flex>
  );
};

PanelTitleLayout.propTypes = {
  children: PropTypes.node,
  isMobile: PropTypes.bool,
};
