import React from "react";
import PropTypes from "prop-types";

import { Button, TextV2 } from "shipnow-mercurio";
import {
  ESTRADA,
  ESTUDIONUBE,
  TOM,
} from "../../../assets/constants/warehouses";

export const GoogleMapsLink = (props) => {
  const { address, children, size, order } = props;
  const warehouseId = order ? order.ship_from_warehouse_id : null;
  const serviceCode = order ? order.shipping_option.service_code : null;

  const prefixText = {
    exchange_warehouse: "El comprador debe llevar la devolución a ",
  };

  const warehouseName = {
    [ESTRADA]: "Shipnow Estrada",
    [ESTUDIONUBE]: "Estudio Nube",
    [TOM]: "Estudio Nube",
  };

  const getChildren = () => {
    if (
      serviceCode === "exchange_warehouse" ||
      serviceCode === "pickup_warehouse"
    ) {
      return warehouseName[warehouseId];
    } else {
      return address;
    }
  };

  return (
    <TextV2 as="span" margin={props.margin}>
      {prefixText[serviceCode]}
      <Button
        href={`https://www.google.com/maps/place/${address}`}
        textsize={size}
        style={{ textAlign: "left" }}
        type="link"
      >
        {children || getChildren()}
      </Button>
    </TextV2>
  );
};

GoogleMapsLink.propTypes = {
  address: PropTypes.string,
  children: PropTypes.node,
  size: PropTypes.string,
  order: PropTypes.object,
  margin: PropTypes.string,
};

GoogleMapsLink.defaultProps = {
  address: null,
  children: null,
  size: "bodyRegular",
  order: null,
  margin: "",
};

export default GoogleMapsLink;
